/*
 * 업무구분   : 공통
 * 화면 명    : MSPBC008P
 * 화면 설명  : 유파트너 공지사항 팝업 상세 팝업
*/
<template>
  <ur-page-container class="msp" title="팝업" :show-title="true" type="subpage" @on-header-left-click="fn_ClickBtnPrevious">
    <ur-box-container direction="column" alignV="start" class="msp-bc-wrap mb50">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column">
        <div class="alarm-title">
          <p>
            {{iapCntnt}}
          </p>
        </div>

        <div class="alarm-detail-txt">
          <ur-box-container alignV="start" componentid="" direction="column" class="date">
            <div class="full font14rem">
              <span>{{iapReciDtm}}</span>
            </div>
          </ur-box-container>
          <!-- <div class="img-box-sample">
            <img src="/images/bg_img.png">
          </div> -->
          <span v-html="lv_PpVo.content" style="height:auto;"></span>
          <span class="btn_click" @click="getUrl(lv_PpVo)"></span>
        </div>
      </ur-box-container>

      <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative noshadow">
        <!-- <div class="relative-div">
          <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round file" @click="open">
            첨부파일 총 2건 (0.14MB)
            <mo-icon>download</mo-icon>
          </mo-button>
        </div> -->
        <div class="relative-div" v-if="isImg">
          <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_imgDownload(lv_PpVo.auncImgPath)">확대보기</mo-button>
        </div>
      </ur-box-container>
    </ur-box-container>

    <!-- Popup001 -->
    <!-- <mo-bottom-sheet ref="attachPopup" close-btn class="ns-bottom-sheet closebtn">
      <template v-slot:title>
        첨부파일
      </template>
       
      <div class="content-area">
        <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="ns-info-list bs-list">
          <mo-list :list-data="mokupdata">
            <template #list-item="{item}">
              <mo-list-item>
                <div class="list-item__contents">
                  <div class="list-item__contents__info">
                    <div class="info_box">
                      <span>{{item.data}}</span>
                      <mo-button componentid="" color="primary" shape="border" class="ns-btn-round h28 blueline fr">보기</mo-button>
                    </div>
                  </div>
                </div>
              </mo-list-item>
            </template>
          </mo-list>

        </ur-box-container>
      </div>
    </mo-bottom-sheet> -->

  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: 'MSPBC008P',
    screenId: 'MSPBC008P',
    components: {},
    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    props: {
      id: {
        type: String,
        default: ''
      },
      iapCntnt: {
        type: String,
        default: ''
      },
      iapReciDtm: {
        type: String,
        default: ''
      },
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {},
    mounted() {
      // 화면접속로그 등록
      this.$bizUtil.insSrnLog(this.$options.screenId)

      this.fn_LoadPopupDtl()
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        isImg: false,
        lv_PpVo: {}
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
      * Function명 : fn_LoadPopupDtl
      * 설명       : 팝업 상세내용을 조회한다.
      ******************************************************************************/
      fn_LoadPopupDtl() {
        let lv_Vm = this
        lv_Vm.isImg = false
        let pParams = {id: this.id}
        const trnstId = 'txTSSBC11S5'
        const auth = 'S'
        this.getStore('progress').dispatch('FULL')
        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            if ( response.body != null ) {
              lv_Vm.lv_PpVo = response.body
              
              // lv_Vm.lv_PpVo.content = lv_Vm.lv_PpVo?.content?.replaceAll('http://ap1.xv.portal.samsunglife.kr:40001', 'http://u-channel.samsunglife.com')
              if (process.env.NODE_ENV === 'production') {
                console.log('운영계 호출')
                lv_Vm.lv_PpVo.content = lv_Vm.lv_PpVo?.content?.replaceAll('http://ap.xp.portal.samsunglife.kr:41000', 'http://u-channel.samsunglife.com')
              } else if (process.env.NODE_ENV === 'stage' || process.env.NODE_ENV === 'stage2') {
                console.log('검증계 호출')
                lv_Vm.lv_PpVo.content = lv_Vm.lv_PpVo?.content?.replaceAll('http://ap1.xv.portal.samsunglife.kr:40001', 'http://u-channel.v.samsunglife.kr:9000')
              }
              
              // [ASR220500236] 사랑On 팝업 내 이미지 확대기능 반영 : 이미지 확대보기 버튼 숨김여부 체크
              if (lv_Vm.lv_PpVo.auncImgPath === '') {
                lv_Vm.isImg = false
              } else {
                lv_Vm.isImg = true
              }
            }
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      },
      /******************************************************************************
      * [ASR220500236] 사랑On 팝업 내 이미지 확대기능 반영
      * Function명 : fn_imgDownload
      * 설명       : 이미지 파일 다운로드 한다
      ******************************************************************************/
      fn_imgDownload (auncImgPath) {
        window.vue.getStore('progress').dispatch('SHOW')
        this.fn_FdpSaveFile(auncImgPath).then(resolve => {
          console.log('파일 다운로드 성공 : ' + resolve)
          this.auncImgName = resolve
          this.fn_FdpOpenFile(this.auncImgName).then(resolve => {
            console.log('파일 오픈 성공 : ' + resolve)
          }, reject => {
            console.log('파일 오픈 실패 : ' + reject)
          }).catch(error => {
            console.log(new Error(error))
          }).finally(() => {
            window.vue.getStore('progress').dispatch('HIDE')
          })
        }, reject => {
          window.vue.getStore('progress').dispatch('HIDE')
          console.log('파일 다운로드 실패 : ' + reject)
        }).catch(error => {
          window.vue.getStore('progress').dispatch('HIDE')
          console.log(new Error(error))
        })
      },
      /******************************************************************************
      * [ASR220500236] 사랑On 팝업 내 이미지 확대기능 반영
      * Function명 : fn_FdpSaveFile
      * 설명       : 첨부파일을 저장한다.
      ******************************************************************************/
      fn_FdpSaveFile (auncImgPath) {
        return new Promise((resolve, reject) => {
          window.fdpbridge.exec('downloadPlugin', {
            path: 'NOLTR_APND_IMG_DIR',
            filename: auncImgPath, // src Directory + fileName
            type: auncImgPath.substring(auncImgPath.lastIndexOf('.') + 1, auncImgPath.length),
            localpath: '/sli/tss/comm/notice', // local Directory
            localfilename: auncImgPath.substring(auncImgPath.lastIndexOf('/') + 1, auncImgPath.length), // local fileName
            rename: 'Y'
          }, (result) => {
            console.log('파일 다운로드 성공 콜백 : ' + result.data)
            resolve(result.data)
          }, (result) => {
            console.log('파일 다운로드 실패 콜백 : ' + result.data)
            reject(result.data)
          })
        })
      },
      /******************************************************************************
      * [ASR220500236] 사랑On 팝업 내 이미지 확대기능 반영
      * Function명 : fn_FdpOpenFile
      * 설명       : 다운로드 파일을 오픈한다.
      ******************************************************************************/
      fn_FdpOpenFile (dImgName) {
        return new Promise((resolve, reject) => {
          window.fdpbridge.exec('intentViewerPlugin', {
            path: '/sli/tss/comm/notice',
            filename: dImgName,
            type: dImgName.substring(dImgName.lastIndexOf('.') + 1, dImgName.length)
          }, (result) => {
            console.log('파일 오픈 성공 콜백 : ' + result.data)
            resolve(result.data)
          }, (result) => {
            console.log('파일 오픈 실패 콜백 : ' + result.data)
            reject(result.data)
          })
        })
      },
      /******************************************************************************
      * Function명 : getUrl
      * 설명       : 팝업공지 링크
      ******************************************************************************/
      getUrl (lv_PpVo) {
        if (lv_PpVo.isUrl) {
          window.fdpbridge.exec('intentBrowserPlugin', {uri: lv_PpVo.linkUrl}, () => {}, () => {})
        }
      },
      /******************************************************************************
       * Function명 : fn_OpenAttachPopup
       * 설명       : 첨부파일 팝업 열기
       ******************************************************************************/
      // fn_OpenAttachPopup() {
      //   this.$refs.attachPopup.open()
      // },
      /******************************************************************************
       * Function명 : fn_ClickBtnPrevious
       * 설명       : 팝업 닫기
       ******************************************************************************/
      fn_ClickBtnPrevious () {
        this.$emit('onPopupClose')
      },
    },
  };
</script>
<style scoped>
</style>