/*
 * 업무구분   : 업무공통
 * 화면 명    : MSPBC003P
 * 화면 설명  : 홈 메뉴 설정(FC)
 */
 <template>
  <ur-page-container class="msp home_new" type="subpage" :show-title="true" title="홈메뉴 설정" :topButton="false"><!-- type-popup -->

    <!-- Content영역 -->
    <template #frame-header-fixed>
      <ur-box-container alignV="start" componentid="ur_box_container_010" direction="row" class="fexTy3 pb16 pl24 pr24 mt30 expand_bottomline fs16rem">
        <span><b><em class="em_normal--2 crTy-blue3">{{lv_checkedItems.length}}</em>개 선택</b>(최대 24개)</span>
        <div class="fexTy1">
          <span>선택보기</span>
          <mo-switch v-model="lv_IsOnlyChecked" small class="ns-switch ml10"/>
        </div>
      </ur-box-container>
    </template>
    <ur-box-container direction="column" alignV="start" class="msp-bc-wrap">      
      <ur-box-container alignV="start" componentid="" direction="column" class="pl24 pr24 mt20 mb80">
        <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="ns-info-list bs-list">
          <mo-list :list-data="fn_GetHomeMenuList" class="home_menu--set">
            <template #list-item="{item, index}">
              <mo-list-item @click.native.stop="fn_OnClickListItem(index)">
                <div class="">
                  <div class="item">
                    <div class="fexTy3-1 relative h100">
                      <mo-checkbox :ref="'checkbox' + index" v-model="lv_checkedItems" :value="item.menuId" @click="fn_OnClickCheck" @input="fn_OnInputCheck" class="checkbox-number chk_fixed" :disabled="item.menuFixYn === 'Y'" :class="'num' + fn_GetCheckedNum(item)">{{item.menuNm}}</mo-checkbox>
                    </div>
                  </div>
                </div>
              </mo-list-item>
            </template>
          </mo-list>
        </ur-box-container>      
      </ur-box-container>
    </ur-box-container>

    <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
      <div class="relative-div">
        <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="$emit('closed')">취소</mo-button>
        <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_InsHomMenuHypt">저장</mo-button>
      </div>
    </ur-box-container>

  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: 'MSPBC003P',
  screenId: 'MSPBC003P',
  components: {},
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    this.fn_SelListHomHyptMenu()
  },
  mounted() {},
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  props: {
    savedHomeMenuList: {
      type: Array,
    },
  },
  data() {
    return {
      lv_IsOnlyChecked: false,
      lv_homMenuList: [],
      lv_checkedItems: []
    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    fn_GetHomeMenuList() {
      return this.lv_IsOnlyChecked ? this.fn_GetCheckedMenuList() : this.lv_homMenuList
    }
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /*********************************************************
     * Function명: fn_SelListHomHyptMenu
     * 설명: 홈 설정 메뉴 조회
     *********************************************************/
    fn_SelListHomHyptMenu() {
      const pParams = {}
      const trnstId = 'txTSSBC40S9'
      return this.post(this, pParams, trnstId, 'S').then(response => {
        if (response?.body) {
          const body = response.body
          if (!body.rsltFlag) {
            this.$addSnackbar('오류가 발생했습니다.')
            return
          }
          if (!body.homMenuList) {
            return
          }
          this.lv_homMenuList = body.homMenuList.filter(item => !!item.menuId)
          // 전체 메뉴
          this.lv_checkedItems = body.homMenuList?.filter(item => item.menuFixYn == 'Y').map(item => item.menuId) || []
          // 설정된 메뉴 merge
          this.lv_checkedItems = this.lv_checkedItems.concat(this.savedHomeMenuList?.map(item => item.menuId)).filter(Boolean)
          // 중복제거
          this.lv_checkedItems = [...new Set(this.lv_checkedItems)]
        }
      }).catch(function (error) {
        window.vue.error(error)
      })
    },
    fn_GetCheckedNum(item) {
      return this.lv_checkedItems.indexOf(item.menuId) + 1
    },
    fn_GetCheckedMenuList() {
      return this.lv_homMenuList
        .filter(item => this.lv_checkedItems.includes(item.menuId))
        .sort((a, b) => this.fn_GetCheckedNum(a) > this.fn_GetCheckedNum(b) ? 1 : -1)
    },
    fn_OnClickListItem(index) {
      const checkbox = this.$refs['checkbox' + index]
      if (checkbox.disabled) return
      checkbox.onClick()
    },
    fn_OnClickCheck() {
      this.lv_checkedItemsBak = _.clone(this.lv_checkedItems)
    },
    fn_OnInputCheck() {
      if (this.lv_checkedItems.length > 24) {
        this.lv_checkedItems.pop()
        this.$showSnackbar('홈메뉴는 24개까지 설정하실 수 있습니다.')
      } else if (this.lv_checkedItems.length < 3) {
        this.lv_checkedItems = this.lv_checkedItemsBak
        this.$showSnackbar('홈메뉴는 3개 이상 설정하셔야 합니다.')
      }
    },
    /*********************************************************
     * Function명: fn_InsHomMenuHypt
     * 설명: 홈 설정 메뉴 등록
     *********************************************************/
    fn_InsHomMenuHypt() {
      const pParams = {
        homMenuHyptList: this.fn_GetCheckedMenuList()
      }
      const trnstId = 'txTSSBC40U1'
      return this.post(this, pParams, trnstId, 'S').then(response => {
        if (response?.body) {
          const body = response.body
          if (!body.rsltFlag) {
            this.$addSnackbar('오류가 발생했습니다.')
            return
          }
          this.$emit('saved')
        }
      }).catch(function (error) {
        window.vue.error(error)
      })
    },
  }
};
</script>
<style scoped>
</style>