/*
 * 업무구분   : 공통
 * 화면 명    : MSPBC200P
 * 화면 설명  : 유파트너 홈 팝업
 */
<template>
    <mo-bottom-sheet ref="homePop" class="ns-bottom-sheet home-pop" @closed="$emit('finished')">
      <template v-slot:title>
        <div class="header maxw100-120">
          새로운 소식이 있습니다.
        </div>
        <div class="header-bg"></div>
      </template>
       
      <div class="content-area mhAuto">
        <ur-box-container alignV="start" componentid="" direction="column" class="home-pop-cont">
          <mo-list :list-data="lv_PopupData.list">
            <template #list-item="{item, index}">
              <ur-box-container alignV="center" componentid="" direction="row" class="home-pop-li" @click.native="fn_SelPopupDetail(item)">
                <span class="count">{{index + 1}}</span>
                <span class="txt txtSkip">{{item.iapCntnt}}</span>
                <mo-button class="link-arrow"></mo-button>
              </ur-box-container>  
            </template>
          </mo-list> 
        </ur-box-container>
      </div>

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <!-- <mo-button componentid="mo_button_005" color="primary" shape="solid" size="medium" @click="close" class="ns-btn-round white">건너띄기</mo-button> -->
            <div class="text-btn" @click="fn_Close(true)" name="닫기">오늘 하루 보지 않기</div>
            <div class="ns-btn-close" @click="fn_Close()" name="닫기"></div>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
</template>
<script>
/***********************************************************************************
* 공통 라이브러리 INCLUDE 영역	                                                   *
***********************************************************************************/
import MSPBC008P from '@/ui/bc/MSPBC008P.vue'

export default {
  /***********************************************************************************
  * Vue 파일 속성 정의 영역	                                                       *
  ***********************************************************************************/
  name: 'MSPBC200P',
  screenId: 'MSPBC200P',
  components: {},
/***********************************************************************************
  * Life Cycle 함수 정의 영역	                                                     *
  ***********************************************************************************/
  created() {},
  mounted() {
    this.key = 'HOME_POP'
    if (localStorage.getItem(this.key) !== this.today) {
      this.fn_SelPopupList()
    }
    // !this.$commonUtil.getIsIOS() &&
    // setTimeout(() => {
    //   window.fdpbridge.exec('stopWifiSSIDPlugin', {},
    //     () => console.log('stopWifiSSIDPlugin success'),
    //     (error) => console.log('[stopWifiSSIDPlugin] error', error)
    //   )
    // }, 1000)
  },
  activated() {
    if (localStorage.getItem(this.key) !== this.today) {
      this.fn_SelPopupList()
    }
  },
  deactivated() {
    this.fn_Close()
  },
  /***********************************************************************************
  * 화면변수 선언 영역	                                                             *
  ***********************************************************************************/
  data() {
    return {
      lv_PopupData: {
        list: [], // 팝업리스트
        totCnt: 0,
        iapKndCd: '7', // 알람종류코드(4:시스템공지, 5:공지사항(전사), 6:공지사항(지점), 7:팝업)
      },
    };
  },
  /***********************************************************************************
  * Computed 함수 정의 영역                                                         *
  ***********************************************************************************/
  computed: {
    today() {
      return new Date().toISOString().substr(0, 10)
    },
  },
  /***********************************************************************************
  * 사용자 함수 정의 영역                                                           *
  ***********************************************************************************/
  methods: {
    /*********************************************************
     * Function명: fn_SelPopupList
     * 설명: 팝업리스트 조회
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_SelPopupList () {
      const pParams = {
        iapKndCd: this.lv_PopupData.iapKndCd
      }
      const trnstId = 'txTSSBC11S4'
      const auth = 'S'
      this.post(this, pParams, trnstId, auth).then(response => {
        // 서버 데이터 가져오기
        if (response?.body) {
          this.lv_PopupData.list = response.body.ppReturnList || []
          this.lv_PopupData.totCnt = response.body.ppTot || 0

          if (this.lv_PopupData.list.length) {
            // this.lv_PopupData.list = this.lv_PopupData.list.slice(0, 4)  // 4개만 표시
            this.fn_Open()
          } else {
            this.$emit('finished')
          }
        }
      })
      .catch(function (error) {
        window.vue.error(error)
      })
    },
    /*********************************************************
     * Function명: fn_SelPopupDetail
     * 설명: 팝업상세조회
     *********************************************************/
    fn_SelPopupDetail (pItem) {
      if (!this.fn_WifiNetCheck()) return
      if (!pItem) {
        return
      }

      this.popupBC008P = this.$bottomModal.open(MSPBC008P, {
        properties: {
          id: pItem.id,
          iapCntnt: pItem.iapCntnt,
          iapReciDtm: pItem.iapReciDtm,
        },
        listeners: {
          onPopupClose: () => {
            if ( this.popupBC008P ) {
              this.$bottomModal.close(this.popupBC008P)
              this.popupBC008P = ''
            }
          },
        }
      },
      {
        properties: {
          noHeader: true
        }
      })
    },
    /******************************************************************************
    * Function명 : fn_WifiNetCheck
    * 설명       : 사내망 체크
    ******************************************************************************/
    fn_WifiNetCheck () {
      if (!this.$MenuManager.fn_GetInternalNetwork()) {
        this.getStore('confirm').dispatch('ADD', '사내Wifi망에서만 접속 가능합니다.')
        return false
      }
      return true
    },
    fn_Open() {
      this.$refs.homePop.open()
    },
    fn_Close(isSuppressToday) {
      this.$refs.homePop.close()
      isSuppressToday && localStorage.setItem(this.key, this.today)
    },
  }
};
</script>
